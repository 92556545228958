import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreferencesByCompany } from './mpSlice';
import { Row, Col } from '@commonsku/styles';
import { LabeledSelect } from '../helpers';
import { createOptions } from '../../utils';
import { useIdentity } from '../../hooks';
import { default_prefs, default_opts } from './utils';

function MPPrefsForm({ fetchStats, disable }) {
  const dispatch = useDispatch();

  const identity = useIdentity();
  const mp_preferences = useSelector(s => {
    return _.get(s, 'marketing_platforms.pereferences', default_prefs);
  });

  const industries = useSelector(({ temp_data }) => {
    const vals = _.values(temp_data.industries);
    return _.isEmpty(vals)
      ? default_opts
      : default_opts.concat(createOptions(vals, 'industry_id', 'industry_name'));
  });
  const account_statuses = useSelector(({ temp_data }) => {
    const vals = _.values(temp_data.account_statuses);
    return _.isEmpty(vals)
      ? default_opts
      : default_opts.concat(createOptions(vals, 'account_status_id', 'account_status_name'));
  });
  const departments = useSelector(({ temp_data }) => {
    const vals = _.values(temp_data.departments);
    return _.isEmpty(vals)
      ? default_opts
      : default_opts.concat(createOptions(vals, 'department_id', 'department_name'));
  });

  function updatePreferences(data) {
    Promise.resolve(dispatch(updatePreferencesByCompany(identity.company_id, data)))
      .then(res => {
        fetchStats();
      });
  }

  const onSelectChange = (name, isMulti = false) => (val, d) => {
    if (isMulti) {
      const opts = ['all', 'none'];
      if (!val) {
        updatePreferences({ [name]: ['all'] });
      } else if (d && d.option && opts.includes(d.option.value)) {
        updatePreferences({ [name]: [d.option.value] });
      } else {
        let value = val;
        if (val) {
          value = val.map(v => v.value).filter(v => !opts.includes(v));
        }
        updatePreferences({ [name]: value });
      }
    } else {
      updatePreferences({ [name]: val.value });
    }
  };

  return (
    <Row style={{ marginTop: 15 }}>
      <Col xs>
        <LabeledSelect inPopup isMulti
          isClearable={false}
          isDisabled={disable}
          label="Client Status"
          value={mp_preferences.client_status}
          options={account_statuses}
          name="client_status"
          onChange={onSelectChange('client_status', true)}
        />
      </Col>
      <Col xs>
        <LabeledSelect inPopup isMulti
          isClearable={false}
          isDisabled={disable}
          label="Department"
          value={mp_preferences.department}
          options={departments}
          name="department"
          onChange={onSelectChange('department', true)}
        />
      </Col>
      <Col xs>
        <LabeledSelect inPopup isMulti
          isClearable={false}
          isDisabled={disable}
          label="Industry"
          value={mp_preferences.industry}
          options={industries}
          name="industry"
          onChange={onSelectChange('industry', true)}
        />
      </Col>
    </Row>
  );
}

export default MPPrefsForm;
