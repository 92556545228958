import { createSlice } from '@reduxjs/toolkit';
import { oauth,  } from '../../utils';

const MAX_RESULTS = 4294967296;

const tempDataSlice = createSlice({
  name: 'temp_data',
  initialState: {
    client_reps: [],
    clients: [],
    tags: [],
    industries: [],
    account_statuses: [],
    departments: [],
    mailchimp_lists: [],
    errors: null,
  },
  reducers: {
    getClientReps(state, action) {
      state.client_reps = action.payload;
    },
    getClients(state, action) {
      state.clients = action.payload;
    },
    getTags(state, action) {
      state.tags = action.payload;
    },
    addTag(state, action) {
      state.tags.push(action.payload);
    },
    getIndustries(state, action) {
      state.industries = action.payload;
    },
    getAccountStatuses(state, action) {
      state.account_statuses = action.payload;
    },
    getDepartments(state, action) {
      state.departments = action.payload;
    },
    getMailchimpLists(state, action) {
      state.mailchimp_lists = action.payload;
    },
    failure(state, action) {
      state.errors = action.payload;
    },
  },
});

export default tempDataSlice;

export const fetchClientReps = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'user', params);
  dispatch(tempDataSlice.actions.getClientReps(json.users));
  return json;
};

export const fetchClients = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'client/toc', params);
  dispatch(tempDataSlice.actions.getClients(json.clients));
  return json;
};

export const fetchTags = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'tag/', params);
  dispatch(tempDataSlice.actions.getTags(json.tags));
  return json;
};

export const addTag = (resource_type, resource_id, label, data={}) => async (dispatch, getState) => {
  const { json } = await oauth('POST', 'tagagged-resource/', {
    resource_type, resource_id, label, ...data
  });
  dispatch(tempDataSlice.actions.addTag(json.tag));
  return json;
};

export const fetchIndustries = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'industry/', params);
  dispatch(tempDataSlice.actions.getIndustries(json.industries));
  return json;
};

export const fetchAccountStatuses = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'account-status/', params);
  dispatch(tempDataSlice.actions.getAccountStatuses(json.account_statuses));
  return json;
};

export const fetchDepartments = (params={'max-results': MAX_RESULTS,}) => async (dispatch, getState) => {
  params = {
    'max-results': MAX_RESULTS,
    ...params
  };

  const { json } = await oauth('GET', 'department/', params);
  dispatch(tempDataSlice.actions.getDepartments(json.departments));
  return json;
};

export const fetchMailchimpLists = () => async (dispatch, getState) => {
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {
      'platform': 'mailchimp',
      'action': 'get-lists',
      '_action': 'get-lists',
    });
    dispatch(tempDataSlice.actions.getMailchimpLists(json.data));
    return true;
  } catch(e) {
    console.error('saveWishpond', e);
    dispatch(tempDataSlice.actions.failure('Unable to update.'));
  }
};
