import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ResponsiveTable, THead, TR, TD, TBody, ButtonsGroup, Button, colors, TH, } from '@commonsku/styles';
import {
  fetchSyncStatusDetails,
} from '../marketing-platforms/mpSlice';
import { window } from '../../global';

function ContactsTab({ type, action, default_comments='' }) {
  const dispatch = useDispatch();
  const contacts = useSelector(s => _.get(s, `marketing_platforms.sync_details.${type}`, []));

  const [trHovering, setTRHovering] = useState(null);
  const [filters, setFilters] = useState({
    limit: 20,
    offset: 0,
  });

  const has_next_page = (!_.isEmpty(contacts) && contacts.length >= filters.limit && filters.offset >= 0);
  const has_prev_page = (!_.isEmpty(contacts) && contacts.length < filters.limit && filters.offset >= filters.limit);

  const gotoNextPage = () => {
    setFilters({ ...filters, offset: filters.offset + filters.limit });
  };

  const gotoPrevPage = () => {
    setFilters({ ...filters, offset: filters.offset - filters.limit });
  };

  useEffect(() => {
    dispatch(fetchSyncStatusDetails({[type]: 'y', ...filters}));
  }, [filters]);

  return (
    <Row>
      <Col xs>
        <ResponsiveTable>
          <THead>
            <TR>
              <TH padded>Email</TH>
              <TH padded>Name</TH>
              <TH padded>Status</TH>
            </TR>
          </THead>
          <TBody>
            {_.map(contacts, (v, i) => {
              const trStyle = {};
              if (trHovering == i) {
                trStyle['background'] = colors.bggray;
                trStyle['cursor'] = "pointer";
              } else {
                trStyle['background'] = i%2 !== 0 ? `${colors.bggray}` : '#fff';
              }
              return (
                <TR key={`${type}_mo_contacts_`+i}
                  style={trStyle}
                  onClick={e => window.open(`/contact/${v.company_contact_id}`, '_blank')}
                  onMouseEnter={e => setTRHovering(i)}
                  onMouseLeave={e => setTRHovering(null)}
                >
                  <TD padded style={trStyle}>{v.contact_email}</TD>
                  <TD padded style={trStyle}>{`${v.contact_first_name} ${v.contact_last_name}`}</TD>
                  <TD padded style={trStyle}>{action} ({v.mpc_comments || default_comments})</TD>
                </TR>
              );
            })}
          </TBody>
        </ResponsiveTable>
      </Col>
      <Col xs padded style={{marginTop: 10}}>
        <Row>
          <Col xs={6} style={{textAlign: 'left'}}>
            <Button disabled={!has_prev_page} onClick={gotoPrevPage}>Prev</Button>
          </Col>
          <Col xs={6} style={{textAlign: 'right'}}>
            <Button disabled={!has_next_page} onClick={gotoNextPage}>Next</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ContactsTab;
