import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { oauth, delay } from '../../utils';
import { updateMarketingSoftwareData, updateMarketingSoftwareKeyValue } from '../../reducers/temp';
import { window } from '../../global';
import { parseMailchimpCampaigns } from './utils';
import { toast } from 'react-toastify';

const successToast = (msg) => {
  if (typeof window.RESKU_APP_PROPS !== 'undefined' && typeof window.RESKU_APP_PROPS.successToast !== 'undefined') {
    window.RESKU_APP_PROPS.successToast(msg);
  } else {
    toast.success(msg);
  }
};
const errorToast = (msg) => {
  if (typeof window.RESKU_APP_PROPS !== 'undefined' && typeof window.RESKU_APP_PROPS.errorToast !== 'undefined') {
    window.RESKU_APP_PROPS.errorToast(msg);
  } else {
    toast.error(msg);
  }
};

const mpSlice = createSlice({
  name: 'marketing_platforms',
  initialState: {
    platform: null,
    pereferences: {},
    platform_data: {},
    fields_mapping: {},
    mp_campaigns_pagination: {},
    mp_campaigns: {},
    mp_contact_activity_pagination: {},
    mp_contact_activity: {},
    sync_details: {},
    error: null,
    loading: false,
    valid_mp_contact: false,
    start_sync: false,
    syncing_status: null,
    sync_state: null,
  },
  reducers: {
    setPlatform(state, action) {
      state.platform = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSyncingStatus(state, action) {
      state.syncing_status = action.payload;
    },
    setSyncState(state, action) {
      state.sync_state = action.payload;
    },
    getCompanyPreferencesSuccess(state, action) {
      state.error = null;
      state.pereferences = action.payload;
      state.loading = false;
    },
    updateCompanyPreferences(state, action) {
      state.error = null;
      state.pereferences = {
        ...state.pereferences,
        ...action.payload,
      };
      state.loading = false;
    },
    fetchMPContactsStatsSuccess(state, action) {
      state.error = null;
      state.platform_data = action.payload;
      state.loading = false;
    },
    updateMPSyncStarted(state, action) {
      state.error = null;
      state.platform_data = {
        ...state.platform_data,
        sync_started: action.payload,
      };
      state.loading = false;
    },
    updateMPDuplicatesTotalSuccess(state, action) {
      state.error = null;
      state.platform_data.total_duplicates = action.payload;
      state.loading = false;
    },
    fetchMPfieldsMappingSuccess(state, action) {
      state.error = null;
      state.fields_mapping = action.payload;
      state.loading = false;
    },
    updateMPfieldsMappingSuccess(state, action) {
      state.error = null;
      state.fields_mapping = {
        ...state.fields_mapping,
        ...action.payload,
      };
      state.loading = false;
    },
    startSyncSuccess(state, action) {
      state.error = null;
      state.start_sync = true;
      state.sync_details = {};
    },
    stopSyncSuccess(state, action) {
      state.error = null;
      state.start_sync = false;
      state.sync_details = {};
    },
    fetchSyncStatusDetailsSuccess(state, action) {
      state.error = null;
      state.sync_details = action.payload;
    },
    fetchMPCampaignsSuccess(state, action) {
      state.error = null;
      state.platform = action.payload.platform;
      if (_.get(action.payload, 'is_mp_valid', null) !== null) {
        state.valid_mp_contact = action.payload.is_mp_valid;
      }

      if (action.payload.mp_campaigns) {
        let campaigns = [];
        if (action.payload.platform === 'mailchimp') {
          campaigns = parseMailchimpCampaigns(action.payload.mp_campaigns.campaigns);
          state.mp_campaigns_pagination.total = action.payload.mp_campaigns.total_items;
        } else if (action.payload.platform === 'hubspot') {
          campaigns = action.payload.mp_campaigns.objects;
          state.mp_campaigns_pagination.limit = action.payload.mp_campaigns.limit;
          state.mp_campaigns_pagination.total = action.payload.mp_campaigns.total;
          state.mp_campaigns_pagination.offset = action.payload.mp_campaigns.offset;
        }

        state.mp_campaigns = {
          ...action.payload.mp_campaigns,
          campaigns: _.reduce(campaigns, (acc, v) => ({
            ...acc,
            [v.id]: v
          }), {}),
        };
      }

      state.loading = false;
    },
    fetchMPContactActivitySuccess(state, action) {
      state.error = null;
      state.platform = action.payload.platform;
      if (action.payload.mp_contact_activity) {
        state.mp_contact_activity = action.payload.mp_contact_activity;
      }
      state.loading = false;
    },
    updateDuplicateEmailContacts(state, action) {
      const data = state.platform_data.duplicate_emails_contacts;
      state.platform_data.duplicate_emails_contacts = data.map(v => {
        const result = v;
        if (v.contact_id === action.payload.marketing_contact_id) {
          result['should_sync'] = "yes";
        } else if (action.payload.no_marketing_contact_ids.includes(v.contact_id)) {
          result['should_sync'] = "no";
        }
        return result;
      });

      if (_.has(action.payload, 'total_duplicates')) {
        state.platform_data.total_duplicates = action.payload.total_duplicates;
      }
    },
    failure(state, action) {
      state.error = action.payload ||  "Whoops! Looks like something's wrong";
    },
    checkMPContact(state, action) {
      state.valid_mp_contact = action.payload;
    },
  },
});

export const selectors = {
  error: s => _.get(s, 'marketing_platforms.error', null),
  platform: s => _.get(s, 'marketing_platforms.platform', null),
  loading: s => _.get(s, 'marketing_platforms.loading', null),
  syncing_status: s => _.get(s, 'marketing_platforms.syncing_status', null),
  pereferences: s => _.get(s, 'marketing_platforms.pereferences', {}),
  platform_data: s => _.get(s, 'marketing_platforms.platform_data', {}),
  fields_mapping: s => _.get(s, 'marketing_platforms.fields_mapping', {}),
  mp_campaigns: s => _.get(s, 'marketing_platforms.mp_campaigns.campaigns', {}),
  mp_campaign: id => s => _.get(s, `marketing_platforms.mp_campaigns.${id}`, {}),
  mp_contact_activity: s => _.get(s, 'marketing_platforms.mp_contact_activity', {}),
  valid_mp_contact: s => _.get(s, 'marketing_platforms.valid_mp_contact', false),
  sync_state: s => _.get(s, 'marketing_platforms.sync_state', {}),
};

export default mpSlice;

export const updatePreferencesByCompany = (company_id, params={}) => async (dispatch, getState) => {
  let data = params;
  if (params && params.remote_update !== false) {
    delete data.remote_update;
    const { json } = await oauth('PUT', 'marketing-platform-preference/'+company_id, data);
    if (json && json.marketing_platform_preferences) {
      data = json.marketing_platform_preferences;
    }
  }

  dispatch(mpSlice.actions.updateCompanyPreferences(data));
  return data;
};

export const getPreferencesByCompany = (company_id, params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  const { json } = await oauth('GET', 'marketing-platform-preference/'+company_id, params);
  dispatch(mpSlice.actions.getCompanyPreferencesSuccess(json ? json.marketing_platform_preferences : {}));
  return json;
};

export const fetchMPContactsStats = (params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  let result = null;
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {
      action: "get-stats",
      mp_prefs: params.mp_prefs
    });

    if (params.get_duplicate_count == 1) {
      dispatch(mpSlice.actions.updateMPDuplicatesTotalSuccess(json.total_duplicates));
    } else {
      dispatch(mpSlice.actions.fetchMPContactsStatsSuccess(json));

      const toggleSync = params.toggleSync;
      if (toggleSync) {
        if (toggleSync && json.sync_started != '1' && (
          !json.total_contacts || (json.total_contacts && json.total_contacts <= 0)
        )) {
          errorToast('Cannot perform this action. No contacts found.');
          result = false;
        } else if (json.sync_started == '1') {
          const isConfirmed = window.confirm('Are you sure you want to stop? All synchronizations will be stopped.');
          if(isConfirmed) {
            dispatch(stopSync());
          }
        } else {
          dispatch(updatePreferencesByCompany(toggleSync.company_id, params.mp_prefs));

          if (!toggleSync.can_sync && toggleSync.sync == 1) {
            errorToast("Cannot start sync. Please check all the fields are filled in.");
            return;
          }
          dispatch(startSync());
        }
      }
    }

    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    result = json;
  } catch(e) {
    console.error('fetchMPContactsStats', e);
    dispatch(mpSlice.actions.failure());
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast(e.json && e.json.error || "Whoops! Looks like something went wrong. Please try again.");
  }
  dispatch(mpSlice.actions.setLoading(false));
  return result;
};

export const fetchMPfieldsMapping = (company_id, params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  let result = null;
  try {
    const { json } = await oauth('GET', 'marketing-platform-company-field/'+company_id, params);
    dispatch(mpSlice.actions.fetchMPfieldsMappingSuccess(json ? json.marketing_platform_company_fields : {}));
    result = json ? json.marketing_platform_company_fields : {};
  } catch(e) {
    console.error('fetchMPfieldsMapping', e);
    dispatch(mpSlice.actions.failure());
  }
  dispatch(mpSlice.actions.setLoading(false));
  return result;
};

export const updateMPfieldsMapping = (company_id, params={}) => async (dispatch, getState) => {
  try {
    await oauth('PUT', 'marketing-platform-company-field/'+company_id, params);
    dispatch(mpSlice.actions.updateMPfieldsMappingSuccess(params));
    return params;
  } catch(e) {
    dispatch(mpSlice.actions.failure());
  }
  return null;
};

export const startSync = (clearStatus=true) => async (dispatch, getState) => {
  let result = false;
  dispatch(mpSlice.actions.setSyncingStatus('Starting'));
  try {
    const { json } = await oauth('POST', 'marketing-platform/', {action: "start-sync"});
    dispatch(mpSlice.actions.startSyncSuccess());
    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    await dispatch(fetchSyncStatusDetails());
    dispatch(mpSlice.actions.setSyncState('Auditing'));
    successToast('Starting Sync.');
    result = true;
  } catch(e) {
    console.error('startSync', e);
    dispatch(mpSlice.actions.failure());
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast((e && e.json && e.json.error) || 'Unable to start sync.');
  }
  if (clearStatus) {
    dispatch(mpSlice.actions.setSyncingStatus('Started'));
  }
  return result;
};

export const stopSync = (clearStatus=true) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setSyncingStatus('Stopping'));
  let result = false;
  try {
    const { json } = await oauth('POST', 'marketing-platform/', {action: "stop-sync"});
    dispatch(mpSlice.actions.stopSyncSuccess());
    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    dispatch(fetchMPContactsStats());
    successToast('Stopping Sync.');
    result = true;
  } catch(e) {
    console.error('stopSync', e);
    dispatch(mpSlice.actions.failure());
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast((e && e.json && e.json.error) || 'Unable to stop sync.');
  }
  if (clearStatus) {
    dispatch(mpSlice.actions.setSyncingStatus('Stopped'));
  }
  return result;
};

export const fetchSyncStatusDetails = (params={}) => async (dispatch, getState) => {
  let result = false;
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {action: "get-sync-status-details", ...params});
    dispatch(mpSlice.actions.fetchSyncStatusDetailsSuccess(json.sync_status_details));
    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    if (json && json.sync_started !== undefined && json.sync_started !== null) {
      dispatch(mpSlice.actions.updateMPSyncStarted(json.sync_started));
    }
    result = true;
  } catch(e) {
    console.error('fetchSyncStatusDetails', e);
    dispatch(mpSlice.actions.failure('Unable to get sync status.'));
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast('Unable to get sync status.');
  }
  return result;
};

export const fetchMpCampaigns = (params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  let result = null;
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {action: 'get-campaigns', ...params});
    dispatch(mpSlice.actions.fetchMPCampaignsSuccess(json));
    result = json;
  } catch(e) {
    console.error('fetchMpCampaigns', e);
    dispatch(mpSlice.actions.failure());
  }
  dispatch(mpSlice.actions.setLoading(false));
  return result;
};

export const fetchMPContactActivity = (email, contact_id, params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  let result = null;
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {action: 'get-contact-activity', email, contact_id, ...params});
    dispatch(mpSlice.actions.fetchMPContactActivitySuccess(json));
    result = json;
  } catch(e) {
    console.error('fetchMPContactActivity', e);
    dispatch(mpSlice.actions.failure());
  }
  dispatch(mpSlice.actions.setLoading(false));
  return result;
};

export const checkMPContact = (contact_id, params={}) => async (dispatch, getState) => {
  dispatch(mpSlice.actions.setLoading(true));
  let result = null;
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {action: 'check-mp-contact', contact_id, ...params});
    dispatch(mpSlice.actions.checkMPContact(json.is_mp_valid));
    result = json.is_mp_valid;
  } catch(e) {
    console.error('checkMPContact', e);
    dispatch(mpSlice.actions.failure());
  }
  dispatch(mpSlice.actions.setLoading(false));
  return result;
};

export const reSyncMPFailedPending = (params={}) => async (dispatch, getState) => {
  let result = null;
  dispatch(mpSlice.actions.setSyncingStatus('Starting'));
  try {
    const { json } = await oauth('POST', 'marketing-platform/', {
      action: 're-sync-pending-failed', ...params
    });
    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    successToast("Sync started for pending/failed contacts.");
    result = true;
  } catch(e) {
    console.error('reSyncMPFailedPending', e);
    dispatch(mpSlice.actions.failure('Unable to restart sync. Please try again later.'));
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast((e && e.json && e.json.error) || "Unable to restart sync. Please try again later.");
  }
  dispatch(mpSlice.actions.setSyncingStatus('Started'));
  return result;
};

export const reSyncMPArchived = (params={}) => async (dispatch, getState) => {
  let result = null;
  dispatch(mpSlice.actions.setSyncingStatus('Starting'));
  try {
    const { json } = await oauth('POST', 'marketing-platform/', {
      action: 're-sync-archived', ...params
    });
    if (json && json.sync_state !== undefined) {
      dispatch(mpSlice.actions.setSyncState(json.sync_state));
    }
    await dispatch(fetchSyncStatusDetails());
    dispatch(mpSlice.actions.setSyncState('Syncing'));
    successToast("Sync started for archived/deleted contacts.");
    result = true;
  } catch(e) {
    console.error('reSyncMPArchived', e);
    dispatch(mpSlice.actions.failure('Unable to restart sync. Please try again later.'));
    dispatch(mpSlice.actions.setSyncState(null));
    errorToast((e && e.json && e.json.error) || "Unable to restart sync. Please try again later.");
  }
  dispatch(mpSlice.actions.setSyncingStatus('Started'));
  return result;
};

export const saveWishpond = (company_id, {
  wishpond_merchid,
  wishpond_tracking,
  wishpond_api
}) => async (dispatch, getState) => {
  try {
    const { json } = await oauth('PUT', 'company-data/' + company_id, {
      wishpond_merchid,
      wishpond_tracking,
      wishpond_api
    });
    successToast("Wishpond data updated");
    dispatch(updateMarketingSoftwareData({
      wishpond_merchid,
      wishpond_tracking,
      wishpond_api
    }));
  } catch(e) {
    console.error('saveWishpond', e);
    dispatch(mpSlice.actions.failure('Unable to update.'));
    errorToast((e && e.json && e.json.error) || "Unable to update");
  }
};

export const disconnectMailchimp = (company_id) => async (dispatch, getState) => {
  try {
    const { json } = await oauth('PUT', 'company-data/' + company_id, {
      mailchimp_access_token: '',
      mailchimp_server_prefix: '',
      mailchimp_list_id: '',
    });
    successToast("Mailchimp Disconnected");
    dispatch(updateMarketingSoftwareData({
      mailchimp_connected: false,
      mailchimp_list_id: '',
    }));
    return true;
  } catch(e) {
    console.error('disconnectMailchimp', e);
    dispatch(mpSlice.actions.failure('Unable to update.'));
    errorToast((e && e.json && e.json.error) || "Unable to update");
  }

  return false;
};

export const disconnectHubspot = (company_id) => async (dispatch, getState) => {
  try {
    const { json } = await oauth('PUT', 'company-data/' + company_id, {
      hubspot_access_token: '',
      hubspot_refresh_token: '',
      hubspot_token_expiry: '',
      hubspot_user_id: '',
      hubspot_portal_id: '',
    });
    dispatch(updateMarketingSoftwareKeyValue('hubspot_connected', false));
    successToast("HubSpot Disconnected");
    return true;
  } catch(e) {
    console.error('disconnectHubspot', e);
    dispatch(mpSlice.actions.failure('Unable to update.'));
    errorToast((e && e.json && e.json.error) || "Unable to update");
  }

  return false;
};

export const updateMailchimpList = (company_id, mailchimp_list_id) => async (dispatch, getState) => {
  try {
    const { json } = await oauth('PUT', 'company-data/' + company_id, {mailchimp_list_id});
    dispatch(updateMarketingSoftwareKeyValue('mailchimp_list_id', mailchimp_list_id));
    successToast("Successfully Updated");
    return true;
  } catch(e) {
    console.error('updateMailchimpList', e);
    dispatch(mpSlice.actions.failure('Unable to update.'));
    errorToast((e && e.json && e.json.error) || "Unable to update");
  }

  return false;
};

export const updateMarketingSoftware = (company_id, marketing_software, toastMessage = true) => async (dispatch, getState) => {
  try {
    const { json } = await oauth('PUT', 'company-data/' + company_id, {marketing_software});
    dispatch(updateMarketingSoftwareKeyValue('marketing_software', marketing_software));
    if (toastMessage) successToast("Successfully Updated");
    return true;
  } catch(e) {
    console.error('updateMarketingSoftware', e);
    dispatch(mpSlice.actions.failure('Unable to update.'));
    errorToast((e && e.json && e.json.error) || "Unable to update");
  }

  return false;
};

export const getConnectedStatus = () => async (dispatch, getState) => {
  try {
    const { json } = await oauth('INDEX', 'marketing-platform/', {action: 'get-connected-status'});
    dispatch(updateMarketingSoftwareData(json));
    return true;
  } catch(e) {
    console.error('getConnectedStatus', e);
    dispatch(mpSlice.actions.failure('Unable to get.'));
    errorToast((e && e.json && e.json.error) || "Unable to get");
  }

  return false;
};
