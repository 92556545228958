import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Toggle, ToggleLink, Button, Text, Loading,  } from '@commonsku/styles';
import {
  fetchMPContactsStats,
  reSyncMPFailedPending,
  fetchSyncStatusDetails,
  selectors,
  reSyncMPArchived,
} from '../marketing-platforms/mpSlice';
import { useIdentity } from '../../hooks';
import MPPrefsForm from './MPPrefsForm';

function MPPrefsTab({
  platform_data, sync_started,
  mp_preferences, can_sync,
  syncing_status,
  can_re_sync, sync_state,
}) {
  const dispatch = useDispatch();
  const identity = useIdentity();

  // selectors
  const marketing_software = useSelector(({ temp }) => temp.marketing_software);
  const sync_details = useSelector(s => _.get(s, 'marketing_platforms.sync_details', {}));
  const loading = useSelector(selectors.loading);

  const fetchStats = React.useCallback(
    () => {
      dispatch(fetchMPContactsStats({mp_prefs: mp_preferences}));
    },
    [mp_preferences]
  );

  const handleToggleSync = () => {
    dispatch(fetchMPContactsStats({
      mp_prefs: mp_preferences,
      toggleSync: {
        can_sync,
        sync: true,
        company_id: identity.company_id,
      }
    }));
  };

  const onRefreshSyncStatus = () => {
    dispatch(fetchSyncStatusDetails());
  };

  const onReSyncFailedPending = () => {
    if (can_re_sync) {
      dispatch(reSyncMPFailedPending());
    }
  };

  const onReSyncMPArchived = () => {
    if (_.get(sync_details, 'archived', 0) > 0 ||
        _.get(sync_details, 'deleted', 0) > 0) {
      dispatch(reSyncMPArchived());
    }
  };

  const canRetry = sync_state || [
    'Stopping', 'Starting', 'Refreshing', 'Syncing', 'Auditing'
  ].includes(syncing_status);

  return (
    <Row>
      <Col xs md={6}>
        <MPPrefsForm
          fetchStats={fetchStats}
          disable={sync_started}
        />
      </Col>
      <Col xs md={5} mdOffset={0.5} style={{paddingTop: 35}}>
        <Row>
          <Col xs>
            <Toggle>
                <ToggleLink
                  disabled={!platform_data.total_contacts || (platform_data.total_contacts && platform_data.total_contacts <= 0)}
                  onClick={handleToggleSync}
                  selected={!sync_started}
                  style={{ paddingTop: '0px' }}>{sync_started ? 'Stop Sync' : 'Stopped'}</ToggleLink>
                <ToggleLink
                  disabled={!platform_data.total_contacts || (platform_data.total_contacts && platform_data.total_contacts <= 0)}
                  onClick={handleToggleSync}
                  selected={sync_started}
                  style={{ paddingTop: '0px' }}>{sync_started ? 'Synced' : 'Start Sync'}</ToggleLink>
              </Toggle>
          </Col>
          {sync_state || ['Stopping', 'Starting', 'Refreshing'].includes(syncing_status) ?
            <Col xs padded>
              <Button disabled style={{display: 'inline-flex', padding: 10}}>
                <Loading width={55} height={35} />
                <Text style={{alignSelf: 'center', paddingLeft: 5}}>
                  {sync_state || syncing_status} {
                    ['starting', 'syncing', 'auditing'].includes((sync_state || syncing_status).toLowerCase())
                      ? `${platform_data.total_contacts} contacts` : 'Sync'
                  }
                </Text>
              </Button>
            </Col> : null}

          {sync_state || ['Stopping', 'Starting', 'Refreshing'].includes(syncing_status) ?
            <Col xs padded>
              <Text>Large contact lists may take several minutes.</Text>
            </Col> : null}

          {sync_started ? <>
            <Col xs padded>
              <Text as="p"><strong>{_.get(sync_details, 'active', 0)} / {_.get(sync_details, 'totals', '0')}</strong> contacts synced</Text>
              <Text as="p"><strong>{_.get(sync_details, 'pending', 0)}</strong> contacts pending</Text>
              <Text as="p"><strong>{_.get(sync_details, 'failed', 0)}</strong> errors</Text>
              <Text as="p"><strong>{_.get(sync_details, 'unsubscribed', 0)}</strong> unsubscribed</Text>
              <Text as="p"><strong>{_.get(sync_details, marketing_software == 'HUBSPOT' ? 'deleted' : 'archived', 0)}</strong> {marketing_software == 'HUBSPOT' ? 'deleted' : 'archived'}</Text>
            </Col>
            <Col xs padded>
              <Button onClick={e => onRefreshSyncStatus()}>Refresh</Button>
            </Col>
            {can_re_sync && (_.get(sync_details, 'failed', 0) > 0 || _.get(sync_details, 'pending', 0) > 0) ?
              <Col xs padded>
                <Button onClick={e => onReSyncFailedPending()} disabled={canRetry}>Retry failed/pending</Button>
              </Col>
            : null}
            {marketing_software == 'HUBSPOT' && can_re_sync && (_.get(sync_details, 'archived', 0) > 0 || _.get(sync_details, 'deleted', 0) > 0) ?
              <Col xs padded>
                <Button onClick={e => onReSyncMPArchived()} disabled={canRetry}>Retry archived/deleted</Button>
              </Col>
            : null}
          </> : loading ? <Loading width={55} height={35} /> : <>
            <Col xs padded>
              <Text as="p" padded>
                <strong>{platform_data.total_contacts}</strong> contacts selected.
              </Text>
              {platform_data.total_duplicates && platform_data.total_duplicates > 0 ? <Text as="p" padded>
                <strong>{platform_data.total_duplicates}</strong> of these have emails shared with other contacts. We&apos;ve selected the most recently created contacts.
              </Text> : null}
            </Col>
            <Col xs padded>
              <Button onClick={e => dispatch(fetchMPContactsStats({mp_prefs: mp_preferences}))}>Refresh</Button>
            </Col>
          </>}
        </Row>
      </Col>
    </Row>
  );
}

export default MPPrefsTab;
